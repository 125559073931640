import React from 'react';
import './Home.css';
import icon from '../assets/icon.png';

const app = {
  name: "fomo - see who's going out tn",
  link: "https://fomoooo.com",
  image: icon,
};

const Home = () => (
  <main>
    <h2>Redefining the Future</h2>
    <p>We are a software company that makes apps.</p>
    <section className="apps-section">
      <h3>Our Apps</h3>
      <div className="app-item">
        <a href={app.link} target="_blank" rel="noopener noreferrer">
          <img src={app.image} alt={app.name} />
          <p>{app.name}</p>
        </a>
      </div>
    </section>
  </main>
);

export default Home;
