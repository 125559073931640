import React from 'react';
import './Team.css';
import teamImage1 from '../assets/team1.jpg'; // Replace with actual image path
import teamImage2 from '../assets/team2.jpg'; // Replace with actual image path
import teamImage3 from '../assets/team3.jpg'; // Replace with actual image path

const team = [
  {
    name: "Ignacio Gómez",
    role: "CTO",
    image: teamImage1,
  },
  {
    name: "Joaquín López",
    role: "CFO",
    image: teamImage2,
  },
  {
    name: "Héctor Reyes",
    role: "CEO",
    image: teamImage3,
  }
];

const Team = () => (
  <main>
    <h2>Meet the Team</h2>
    <section className="team-section">
      <div className="team-list">
        {team.map((member, index) => (
          <div key={index} className="team-item">
            <img src={member.image} alt={member.name} />
            <h4>{member.name}</h4>
            <p>{member.role}</p>
          </div>
        ))}
      </div>
    </section>
  </main>
);

export default Team;
