import React from 'react';

const PrivacyPolicy = () => (
  <main>
    <h2>Privacy Policy</h2>
    <p>At Obra Studios, we respect your privacy and are committed to protecting it.</p>
    {/* Add more detailed privacy policy content here */}
  </main>
);

export default PrivacyPolicy;
