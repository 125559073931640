import React from 'react';

const Contact = () => (
  <main>
    <h2>Contact Us</h2>
    <p>Email: info@obrastudios.com</p>
    <p>Phone: (787) 221-7692</p>
  </main>
);

export default Contact;
